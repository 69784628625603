import { Controller } from "stimulus"
import bulmaCalendar from "bulma-calendar"

export default class extends Controller {
  static targets = [ "checkbox", "code" ]

  toggle() {
    // lookup from API
    fetch("/settings/toggle_support/" + this.checkboxTarget.checked)
      .then(response => response.json())
      .then((json) => {
        this.codeTarget.innerHTML = json.string
      })
    if(this.checkboxTarget.checked) {
      this.codeTarget.classList.remove('is-hidden')
    } else {
      this.codeTarget.classList.add('is-hidden')
    }
  }
}
