import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "permission", "box" ]

  initialize() {
    this.enablePermission()
  }

  enablePermission() {
    this.permissionTargets.forEach((el, i) => {
      el.classList.toggle("is-active", parseInt(this.permission_level) == parseInt(el.getAttribute("data-permission-level")) || (this.permission_level == 0 && i == 0))
    })
    this.boxTargets.forEach((el, i) => {
      el.classList.toggle("is-hidden", parseInt(this.permission_level) != parseInt(el.getAttribute("data-permission-level")) || (this.permission_level == 0 && i == 0))
    })
  }

  get permission_level() {
    var val = parseInt(document.getElementById('user_company_users_attributes_permission_level_id').value)
    return isNaN(val) ? 0 : val
  }

  set permission_level(value) {
    document.getElementById('user_company_users_attributes_permission_level_id').value = value
  }

  change(event) {
    this.permission_level = event.srcElement.getAttribute('data-permission-level')
    this.enablePermission()
  }
}
