import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tabs", "elems" ]

  initialize() {}

  get active_tab() {
    document.getElementsByClassName('content-tab is-active')[0].id
  }

  set active_tab(value) {
    this.tabsTargets.forEach((el, i) => {
      el.classList.toggle('is-active', el.id == value);
      el.classList.toggle('is-hidden', el.id != value);
    })
    this.elemsTargets.forEach((el, i) => {
      el.parentElement.classList.toggle('is-active', el.getAttribute('data-tab') == value);
    })
  }

  change(event) {
    this.active_tab = event.srcElement.getAttribute('data-tab')
  }
}
