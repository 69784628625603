import { Controller } from "stimulus"
import bulmaCalendar from "bulma-calendar"

export default class extends Controller {
  static targets = [ "postcode", "streets", "city", "vatEnabled", "vatDetails" ]

  lookup() {
    // lookup from API
    if(this.postcodeTarget.value.length >= 4) {
      fetch("/caclr/lookup/" + this.postcodeTarget.value)
        .then(response => response.json())
        .then((json) => {
          // Empty the list
          var i, L = this.streetsTarget.options.length - 1;
          for(i = L; i >= 0; i--) {
            this.streetsTarget.remove(i);
          }
          // fill the list
          for (var i = 0; i < json.length; i++) {
            var option = document.createElement("option")
            option.text = json[i].rue;
            this.streetsTarget.add(option);
            if(i == 0) {
              this.cityTarget.value = json[i].localite
            }
          }
        })
    } 
  }

  lookupCity() {
    fetch("/caclr/lookup/" + this.postcodeTarget.value)
        .then(response => response.json())
        .then((json) => {
          for (var i = 0; i < json.length; i++) {
            if(json[i].rue == this.streetsTarget.value) {
              this.cityTarget.value = json[i].localite
            }
          }
        })
  }

  toggleVat() {
    if(this.vatEnabledTarget.value == 'false') {
      this.vatDetailsTarget.classList.add("is-hidden")
    } else {
      this.vatDetailsTarget.classList.remove("is-hidden")
    }
  }

  connect() {
    const calendars = bulmaCalendar.attach('[type="date"]', {
      dateFormat: 'YYYY-MM-DD'
    });
  }
}
